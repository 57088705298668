import Image from 'next/legacy/image'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Slider from 'react-slick'
import { getPartners, selectPartners } from '../../redux/slices/partnerSlice'
import { useTranslation } from 'react-i18next'

export default function PartnerOperators() {
  const [t] = useTranslation()
  const mounthOne = useRef(true)
  const dispatch = useDispatch()
  const partnersData = useSelector(selectPartners)

  useEffect(() => {
    if (mounthOne.current) {
      mounthOne.current = false
      dispatch(getPartners())
    }
  }, [])

  const settings = {
    arrows: false,
    infinite: false,
    slidesToShow: 6.4,
    slidesToShow: 5,
    swipeToSlide: true,
    autoplay: false,
    speed: 500,
    responsive: [
      {
        breakpoint: 1380,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 780,
        settings: {
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div className="PartnerOperators" id="partners">
      <h2 className="PartnerOperators__title"> {t('PARTNER_OPERATORS')} </h2>
      <Slider {...settings} className="PartnerOperators__main">
        {partnersData?.data?.map((item, index) => {
          return (
            <div className="PartnerOperators__main__item" key={index}>
              <Image
                src={item?.image || '#'}
                objectFit="contain"
                alt={item.name || 'Picture of the partner'}
                width={180}
                height={100}
                aria-hidden="true"
              />
            </div>
          )
        })}
      </Slider>
    </div>
  )
}
