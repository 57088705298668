import Link from "next/link";
import React from "react";
import { useTranslation } from "react-i18next";
import BtnArrowAnimation from "../public/icons/resources/btnArrowAnimation";

export default function MoreEffective() {
  const [t] = useTranslation();

  return (
    <div className="moreEffective container">
      <h2 className="moreEffective__title">
        {t("MORE_EFFECTIVE_TEXT_1")} <br />{" "}
        <span> {t("MORE_EFFECTIVE_TEXT_2")} </span>
        {t("MORE_EFFECTIVE_TEXT_3")}
      </h2>
      <Link href="/registration" className="moreEffective__btn btn">
        {t("GET_STARTED")} <BtnArrowAnimation />
      </Link>

      {/* Start */}
      {/* Position absolute items */}

      <div className="moreEffective__circle_left circle"></div>
      <div className="moreEffective__circle_rigth circle"></div>
      <div className="gradient_rigth_bootom"></div>

      {/* End */}
    </div>
  );
}
