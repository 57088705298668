import React, { memo, useState } from 'react'

export default memo(function FAQ_item({ data }) {
  const [active, setActive] = useState(false)
  return (
    <div>
      <div
        role="button"
        tabIndex={0}
        className="question"
        onClick={() => {
          setActive(!active)
        }}
      >
        <span
          className={active ? 'icon-minus_content' : 'icon-add_content'}
        ></span>
        <p>{data?.ask}</p>
      </div>
      <div className={active ? 'active answer' : ' answer'}>
        <p dangerouslySetInnerHTML={{ __html: data?.question }}></p>
      </div>
    </div>
  )
})
