import Head from 'next/head'
import BrandsSlider from '../components/sliders/BrandsSlider'
import FAQ_Component from '../components/FAQ_Component'
import Features from '../components/sliders/Features'
import LeranMore from '../components/sliders/LearnMore'
import MoreEffective from '../components/MoreEffective'
import PartnerOperators from '../components/sliders/PartnerOperators'
import Pricing from '../components/Pricing'
import TopProductsSlider from '../components/sliders/TopProductsSlider'
import TopSlider from '../components/TopSlider'
import { useRouter } from 'next/router'

export default function Home() {
  const isLogedIn = localStorage.getItem('isLogedIn')

  return (
    <div>
      <Head>
        <title>Arforme</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <TopSlider />
      <BrandsSlider />
      <TopProductsSlider />
      <Features />
      <Pricing />
      <LeranMore />
      <FAQ_Component />
      {!isLogedIn && <MoreEffective />}
      <PartnerOperators />
    </div>
  )
}
