import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pricingThunk, selectPricingData } from "../redux/slices/pricingSlice";
import { useTranslation } from "react-i18next";

export default function Pricing() {
  const [t] = useTranslation();
  const pricingData = useSelector(selectPricingData)
  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(pricingThunk())
  }, [])

  return (
    <div className="pricing container" id="pricing">
      <h2 className="pricing__title">
        <span className="text_gradient_blue">ArforMe</span> {t("PRICING")}
      </h2>
      <div className="pricing__main">
        <p className="pricing__main__text">
          {t("PRICING_DESCRIPTION")}
        </p>
        <div className="pricing__main__info_blocks">
          {pricingData?.data?.map((item) => {
            return (
              <div className="item" key={item?.id}>
                <span>{item.name}</span>
                <div>
                  <span>{t("MINIMAL_PRICE")}</span>
                  <span> {item.min_fee}</span>
                </div>
                <span>{item?.service_fee}%</span>
              </div>
            );
          })}
        </div>
      {/* Start */}
      {/* Position absolute items */}

      <div className="pricing__for_circle_left"></div>
      <div className="pricing__for_circle_rigth"></div>

      {/* End */}
      </div>

    </div>
  );
}
