import Image from 'next/legacy/image'
import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Slider from 'react-slick'
import {
  selectWhyChooseUsData,
  whyChooseUsThunk,
} from '../../redux/slices/why_choose_us'
import { useTranslation } from 'react-i18next'

export default function Features() {
  const [t] = useTranslation()
  const whyChooseUsData = useSelector(selectWhyChooseUsData)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(whyChooseUsThunk())
  }, [])

  const settings = {
    arrows: false,
    // infinite: false,
    slidesToShow: 4,
    autoplay: true,
    speed: 750,
    slidesToScroll: 1,
    swipeToSlide: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1380,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div className="features" id="features">
      <div className="features__main">
        <h2 className="features__main__title">
          {t('WHY_CHOOSE_US')} <br /> {t('MAKE_UNAVAILABLE')}{' '}
          <span className="text_gradient_blue">{t('AVAILABLE')}</span>
        </h2>
        <div className="features__main__blocks">
          <Slider {...settings} className="">
            {whyChooseUsData?.data?.map((item) => {
              return (
                <div className="item" key={item?.id}>
                  <div>
                    <Image
                      className="img"
                      height={5}
                      width={5}
                      layout="responsive"
                      alt="Our features"
                      objectFit="cover"
                      src={item?.icon}
                      aria-hidden="true"
                    />
                  </div>
                  <p dangerouslySetInnerHTML={{ __html: item?.text }}></p>
                </div>
              )
            })}
          </Slider>
        </div>
        <div className="circle circle_left"></div>
        <div className="circle circle_rigth"></div>
        <div className="gradiaent_top_rigth"></div>
      </div>

      {/* Start */}
      {/* Position absolute items */}

      <div className="features__backgraund_gradient"></div>

      {/* End */}
    </div>
  )
}
