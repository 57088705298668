import Link from "next/link";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FAQsThunk, selectFAQsData } from "../redux/slices/FAQsSlice";
import FAQ_item from "./FAQ_item";
import { useTranslation } from "react-i18next";

export default function FAQ_Component() {
  const [t] = useTranslation();
  const FAQ_Data = useSelector(selectFAQsData)
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(FAQsThunk())
  }, [])

  return (
    <div className="FAQ_Component container">
      <div>
        <h2 className="FAQ_Component__title">
          {t("FREQUENTLY")} <br /> {t("ASKED_QUESTIONS")}
        </h2>
        <p className="FAQ_Component__text">
          {t("FQA_COMPONENT_DESCRIPTION")}
          <Link href="/contact-us">
            <span> {t("CONTACT_US")} </span>
          </Link>
        </p>
      </div>
      <div className="FAQ_Component__questions">
        {FAQ_Data?.data?.map((item, index) => {
          return <FAQ_item key={index} data={item} />;
        })}
      </div>
    </div>
  );
}
