import Image from 'next/legacy/image'
import Link from 'next/link'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Slider from 'react-slick'
import { getProducts, productSelector } from '../../redux/slices/getShopsSlice'
import { addToCartThunk } from '../../redux/slices/cartSlice'
import Hearth from '../../public/icons/resources/Hearth'
import { addToWishlist } from '../../redux/slices/savedProductsSlice'
import Router from 'next/router'
import { useTranslation } from 'react-i18next'
import img from '../../public/images/default.jpg'

export default function TopProductsSlider() {
  const [t] = useTranslation()
  const mounthOne = useRef(true)
  const dispatch = useDispatch()
  const productData = useSelector(productSelector)
  const [sliderRef, setSliderRef] = useState(null)
  const isLogedIn = localStorage.getItem('isLogedIn')

  useEffect(() => {
    if (mounthOne.current) {
      mounthOne.current = false
      dispatch(getProducts())
    }
  }, [])

  function addWishlist(item, index) {
    if (!isLogedIn) {
      Router.push('/login')
    } else {
      dispatch(addToWishlist({ product_id: item.id })).then(() => {
        dispatch(getProducts())
      })
    }

    // setProductData(
    //   productData?.map((i) => {
    //     if (item.id === i.id) {
    //       return { ...item, is_wished: !item.is_wished };
    //     }
    //     return i;
    //   })
    // );
  }

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 5.5,
    swipeToSlide: true,
    autoplay: true,
    speed: 500,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1.68,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  function addToCart(id) {
    isLogedIn
      ? dispatch(
          addToCartThunk({
            quantity: 1,
            product_id: id,
            // cart_token: !isLogedIn && (localStorage.getItem("token") || ""),
          }),
        )
      : Router.push('/registration')
  }

  return (
    <div className="topProdsSlider">
      {productData?.data?.length ? (
        <h2 className="topProdsSlider__title">
          {t('LEGENDARY_DEALS_FOR_EVERONE')}
        </h2>
      ) : (
        ''
      )}
      {productData?.data?.length ? (
        <div className="link_see_more">
          <Link href="/deals">
            <p className="topProdsSlider__see_more">{t('SEE_MORE_DEALS')}</p>
            <span className="icon-arrow_right_alt" />
          </Link>
        </div>
      ) : (
        ''
      )}
      <div className="topProdsSlider__main">
        <Slider
          ref={setSliderRef}
          {...settings}
          className="topProdsSlider__main"
        >
          {productData?.data?.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  item.status === 'expired'
                    ? 'expired topProdsSlider__main__item'
                    : 'topProdsSlider__main__item'
                }
              >
                <div className="img-block">
                  <Image
                    className="img"
                    height={5}
                    width={5}
                    layout="responsive"
                    alt={item.name || 'product'}
                    objectFit="cover"
                    src={item.images[0] || img}
                  />
                </div>
                <p className="topProdsSlider__main__item__at">
                  {t('AT')} {item.shop_name}
                </p>
                <p className="topProdsSlider__main__item__name">{item.name}</p>
                <div className="topProdsSlider__main__item__costANDad">
                  <div className="cost">
                    <span
                      className={
                        item.discounted_price
                          ? 'price discounted_color'
                          : 'price'
                      }
                    >
                      {item.currency == 'USD' ? item.currency_symbol : ''}
                      {item.price}
                      {item.currency != 'USD' ? item.currency_symbol : ''}
                    </span>
                    {item.discounted_price && (
                      <span className="discounted_price">
                        {item.currency == 'USD' ? item.currency_symbol : ''}
                        {item.discounted_price}
                        {item.currency != 'USD' ? item.currency_symbol : ''}
                      </span>
                    )}
                  </div>
                  {!(item.status === 'expired') && (
                    <div className="adding_btn">
                      <div
                        role="button"
                        className="hearth"
                        onClick={() => addWishlist(item)}
                      >
                        <Hearth className={item.is_wished ? 'active' : ''} />
                      </div>
                      <div
                        className="add"
                        onClick={() => {
                          addToCart(item.id)
                        }}
                      >
                        <span className="icon-add_content" />
                      </div>
                    </div>
                  )}
                </div>
                {/* position absolute */}
                {item?.status === 'expired' && (
                  <div className="experied_tab">
                    <span>{t('EXPIRED')}</span>
                  </div>
                )}
              </div>
            )
          })}
        </Slider>
        {productData?.data?.length ? (
          <div className="controls">
            <button aria-label="prev-arrow" onClick={sliderRef?.slickPrev}>
              <span className="icon-arrow_left_content" />
            </button>
            <button aria-label="next-arrow" onClick={sliderRef?.slickNext}>
              <span className="icon-arrow_rigth_content" />
            </button>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}
