import React from 'react'

export default function Hearth({className}) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="#fff"
      className={className}
    >
      <path
        d="M4.5 12.534a4.966 4.966 0 01-.09-7.022c1.893-1.956 5.14-2.045 7.124-.09.18.179 1.984 2.134 1.984 2.134l-1.45-1.565c1.714-2.133 4.696-2.524 6.86-.924 2.255 1.69 2.796 4.978.992 7.022C18.117 14.222 11.894 20 11.894 20L4.5 12.534z"
        stroke="none"
        strokeWidth={2}
        strokeMiterlimit={7.3333}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
   
    </svg>
  )
}
