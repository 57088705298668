import Link from 'next/link'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectTopSlider, topSliderThunk } from '../redux/slices/topSliderSlice'
import { useTranslation } from 'react-i18next'
import BtnArrowAnimation from '../public/icons/resources/btnArrowAnimation'

export default function TopSlider() {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const TopSliderData = useSelector(selectTopSlider)
  let data = TopSliderData?.data?.[0]

  useEffect(() => {
    dispatch(topSliderThunk())
  }, [])

  return (
    <div className="buy_for_me_section">
      <style>{`
        .buy_for_me_section{
          ${
            !data?.show_background_image
              ? `background: ${data?.style}`
              : `background-image : url(${data.background_image})`
          }
         
        }
      `}</style>
      <div className="buy_for_me_section__main container">
        <h1>{data?.title}</h1>
        <p> {data?.description}</p>
        <div className="buy_for_me_section__main__buttons">
          {data?.button && (
            <Link href={'/place_order'}>
              <div className="palace_order btn">
                {data?.slug_title} <BtnArrowAnimation />
              </div>
            </Link>
          )}
          <div className="how_it_works">
            <div className="play_svg icon-play_arrow_content"></div>
            <span>{t('HOW_IT_WORKS')}</span>
          </div>
        </div>
      </div>

      {/* Start */}
      {/* Position absolute items */}

      <div className="buy_for_me_section__for_gradient"></div>
      {/* <div className="buy_for_me_section__for_circle_left circle"></div> */}
      <div className="buy_for_me_section__for_circle_rigth circle"></div>

      {/* End */}
    </div>
  )
}
