import Image from 'next/legacy/image'
import Link from 'next/link'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Slider from 'react-slick'
import { getShops, selectShops } from '../../redux/slices/getShopsSlice'

export default function BrandsSlider() {
  const mounthOne = useRef(true)
  const dispatch = useDispatch()
  const shopsData = useSelector(selectShops)
  const [t] = useTranslation()

  useEffect(() => {
    if (mounthOne.current) {
      mounthOne.current = false
      dispatch(getShops())
    }
  }, [])
  const slider = useRef(null)

  const settings = {
    arrows: false,
    infinite: true,
    slidesToShow: 7.3,
    autoplay: false,
    speed: 750,
    slidesToShow: 8,
    // slidesToScroll: 1,
    swipeToSlide: true,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1380,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          slidesToShow: 1.4,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div className="brandsSlider">
      {shopsData?.data?.length ? (
        <h2 className="brandsSlider__title">
          {t('WORLDS_LARGEST_ONLINE_SHOPS')}
        </h2>
      ) : (
        ''
      )}
      <Slider {...settings} ref={slider} className="brandsSlider__main">
        {shopsData?.data
          ?.filter((item) => item.image) // Filter out items without an image
          .map((item, index) => {
            return (
              <React.Fragment key={item.id}>
                {item.image && (
                  <div className="brandsSlider__main__item" key={index}>
                    <Link aria-hidden="true" href={item.link} target="_blank">
                      <Image
                        src={item?.image}
                        objectFit="contain"
                        alt={item.title || 'Picture of brand'}
                        width={180}
                        height={100}
                        aria-hidden="true"
                      />
                    </Link>
                  </div>
                )}
              </React.Fragment>
            )
          })}
      </Slider>
    </div>
  )
}
