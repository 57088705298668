import Image from "next/legacy/image";
import Link from "next/link";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { getBlogs, selectBlogs } from "../../redux/slices/blogsSlice";
import { useTranslation } from "react-i18next";

export default function LeranMore() {
  const [t] = useTranslation();
  const mounthOne = useRef(true);
  const [sliderRef, setSliderRef] = useState(null);
  const blogsSliderData = useSelector(selectBlogs);
  const dispatch = useDispatch();

  useEffect(() => {
    if (mounthOne.current) {
      mounthOne.current = false;
      dispatch(getBlogs());
    }
  }, []);

  const settings = {
    arrows: false,
    infinite: false,
    dots: true,
    slidesToShow: 4.4,
    swipeToSlide:true,
    autoplay: true,
    speed: 500,
    responsive: [
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="learnMore">
      <h2 className="learnMore__title">{t("LEARN_MORE_WITH_ARFORME")}</h2>

      <div className="learnMore__see_more">
        <Link href="/blog">
          <span className="text">{t("READ_MORE_BLOGS")}</span>
          <span className="icon-arrow_right_alt"></span>
        </Link>
      </div>
      <Slider ref={setSliderRef} {...settings} className="learnMore__main">
        {blogsSliderData?.data?.map((item, index) => {
          return (
            <Link href={`/blog/${item.id}`} key={index}>
              <div className="learnMore__main__item">
                <div className="img_block">
                  <Image
                    layout="fill"
                    src={item.cover}
                    alt={item?.title || "blog"}
                    objectFit="cover"
                  />
                </div>
                <div className="main">
                  <p className="date">{item.date}</p>
                  <p className="title">{item.title}</p>
                </div>
              </div>
            </Link>
          );
        })}
      </Slider>
      <div className="controls">
        <button  aria-label="prev_arrow" onClick={sliderRef?.slickPrev}>
          <span className="icon-arrow_left_content" />
        </button>
        <button aria-label="next_arrow" onClick={sliderRef?.slickNext}>
          <span  className="icon-arrow_rigth_content" />
        </button>
      </div>
    </div>
  );
}
